<script setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TrainingForm from "./index.vue";
import {
  GET_COURSES_GETTER,
  SET_CURRENT_COURSE_MUTATION,
} from "@/store/modules/MA/staff-trainings/constants";

const store = useStore();
const route = useRoute();
route;
const TrainingData = computed(() => {
  return store.getters[`Trainings/${GET_COURSES_GETTER}`].find(
    (traing) => traing.id === Number(route.params.id)
  );
});
onMounted(() => {
  if (TrainingData.value) {
    store.commit(
      `Trainings/${SET_CURRENT_COURSE_MUTATION}`,
      TrainingData.value
    );
  }
});
</script>
<template>
  <div class="w-full h-full flex flex-col flex-grow">
    <TrainingForm :mode="'edit'" :formData="TrainingData" />
  </div>
</template>